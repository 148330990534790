<template>
    <div>
        <b-row>
            <b-col cols="12">

                <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key="index" variant="danger"
                    dismissible>
                    {{item.message}}</b-alert>
                <b-alert :show="showSuccess == true" variant="success" dismissible>
                    Saved!</b-alert>
            </b-col>

        </b-row>
        <b-row v-if="isLoading" align-h="center" class="mt-5" align-v="center">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner"></b-spinner>
        </b-row>
        <b-row v-if="!isLoading">
            <!-- <b-col cols="9">
                <b-card class="secondary-background">
                    <b-row align-h="between" class="mt-0" align-v="center">
                        <span>Current Location: {{selectedLocation.untName}}</span>
                        <b-dropdown id="dropdown-changeLocation" text="Select New Location" class="m-md-2">
                            <b-dropdown-item v-for="(item, index) in locations" :key="index"
                                @click="changeSelectedLocation(index)">{{item.untName}}</b-dropdown-item>
                        </b-dropdown>
                    </b-row>
                </b-card>
            </b-col> -->
            <b-col cols="12" lg="3">
                <b-card class="primary-background max-height" @click="addNewDevice()">
                    <b-row align-h="center" class="mt-0 max-height" align-v="center">
                        <span class="custom-heading">
                            <b-icon-plus></b-icon-plus>Add New Device
                        </span>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="!isLoading">
            <b-col cols="12">
                <b-card>
                    <h3>Manage Devices</h3>
                    <b-table v-if="locations.length > 0" striped hover :items="locations" :fields="fields"
                        align-v="center" id="summary-table" class="mb-0" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc">

                        <template #cell(actions)="row">
                            <b-row align-h="end" align-v="center" class="mt-0">
                                <b-button class="ml-2" size="sm" variant="primary" @click="editSelection(row)">Edit
                                </b-button>
                            </b-row>
                        </template>

                    </b-table>

                    <p class="text-center mt-5 mb-5" v-if="locations.length <= 0">No devices registered to account</p>
                </b-card>
            </b-col>
        </b-row>

        <b-modal ref="modal-addNewDevice" hide-footer size="xl" title="Add New Device" button-size="sm">

            <label class="mt-0">Unit Identifier</label>
            <b-form-input v-model="newUnit.unitIdentifier" placeholder="Unit Identifier"></b-form-input>

            <label>Description</label>
            <b-form-input v-model="newUnit.description" placeholder="Description"></b-form-input>
            <b-row class="p-0 m-0">
                <b-col cols="4" class="pl-0">
                    <label>Solar Reference Volts</label>
                    <b-form-input v-model="newUnit.untProperties.solarReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Generator Reference Volts</label>
                    <b-form-input v-model="newUnit.untProperties.generatorReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4" class="pr-0">
                    <label>Grid Reference Volts</label>
                    <b-form-input v-model="newUnit.untProperties.gridReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-0">
                <b-col cols="4" class="pl-0">
                    <label>Battery Min Volts</label>
                    <b-form-input v-model="newUnit.untProperties.batteryMinVolts" placeholder="Min Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4" class="pr-0">
                    <label>Battery Max Volts</label>
                    <b-form-input v-model="newUnit.untProperties.batteryMaxVolts" placeholder="Max Volts">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row align-h="end">
                <b-button variant="outline-primary" @click="hideModals()">Cancel</b-button>
                <b-button variant="primary" class="ml-2" @click="saveDevice()">
                    <b-spinner small v-if="isLoading"></b-spinner>Done
                </b-button>
            </b-row>
        </b-modal>
        <b-modal ref="modal-editDevice" hide-footer size="xl" title="Edit Device" button-size="sm">

            <label class="mt-0">Unit Identifier</label>
            <b-form-input v-model="editInfo.unitIdentifier" placeholder="Unit Identifier" :disabled="true">
            </b-form-input>

            <label>Description</label>
            <b-form-input v-model="editInfo.description" placeholder="Description"></b-form-input>
            <b-row class="p-0 m-0">
                <b-col cols="4" class="pl-0">
                    <label>Solar Reference Volts</label>
                    <b-form-input v-model="editInfo.untProperties.solarReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Generator Reference Volts</label>
                    <b-form-input v-model="editInfo.untProperties.generatorReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4" class="pr-0">
                    <label>Grid Reference Volts</label>
                    <b-form-input v-model="editInfo.untProperties.gridReferenceVolts" placeholder="Main Volts">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-0">
                <b-col cols="4" class="pl-0">
                    <label>Battery Min Volts</label>
                    <b-form-input v-model="editInfo.untProperties.batteryMinVolts" placeholder="Min Volts">
                    </b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Battery Max Volts</label>
                    <b-form-input v-model="editInfo.untProperties.batteryMaxVolts" placeholder="Max Volts">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row align-h="end">
                <b-button variant="outline-primary" @click="hideModals()">Cancel</b-button>
                <b-button variant="secondary" class="ml-2" @click="deleteDevice()">Remove</b-button>
                <b-button variant="primary" class="ml-2" @click="editDevice()">
                    <b-spinner small v-if="isLoading"></b-spinner>Done
                </b-button>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        data: () => ({
            errors: [],
            items: [{
                unitName: '1-0923410u2-041',
                description: 'Pretoria'
            }],
            newUnit: {
                unitIdentifier: null,
                description: null,
                untProperties: {
                    mainVolts: null,
                    batteryMaxVolts: null,
                    batteryMinVolts: null
                }
            },
            editInfo: {
                unitIdentifier: null,
                description: null,
                untProperties: {
                    mainVolts: null,
                    batteryMaxVolts: null,
                    batteryMinVolts: null
                }
            },
            showSuccess: false,
            editIndex: -1,
            isLoading: false,
            sortBy: 'date',
            sortDesc: false,
            fields: [{
                    label: 'Unit Identifier',
                    key: 'untIdent',
                    sortable: true
                },
                {
                    label: 'Description',
                    key: 'untName',
                    sortable: true
                },
                {
                    label: 'Solar Reference Volts',
                    key: 'untProperties.solarReferenceVolts',
                    sortable: true
                },
                {
                    label: 'Generator Reference Volts',
                    key: 'untProperties.generatorReferenceVolts',
                    sortable: true
                },
                {
                    label: 'Grid Reference Volts',
                    key: 'untProperties.gridReferenceVolts',
                    sortable: true
                },
                {
                    label: 'Min Volts',
                    key: 'untProperties.batteryMinVolts',
                    sortable: true
                },
                {
                    label: 'Max Volts',
                    key: 'untProperties.batteryMaxVolts',
                    sortable: true
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false
                }
            ]
        }),
        created() {
            this.errors = []
            this.isLoading = true

            this.getLoggedInLocations()
            .then(() => {
                this.isLoading = false
            })
                .catch(() => {
                    this.isLoading = false
                    this.errors.push({
                        message: 'Failed to load locations. Please refresh and try again.'
                    })
                })

        },
        methods: {
            ...mapActions(['getLoggedInLocations', 'updateDevice', 'removeDevice']),
            addNewDevice() {
                //show modal
                this.$refs['modal-addNewDevice'].show()
                this.newUnit = {
                    unitIdentifier: null,
                    description: null,
                    untProperties: {
                        mainVolts: null,
                        batteryMaxVolts: null,
                        batteryMinVolts: null
                    }
                }
            },
            editSelection(row) {
                this.$refs['modal-editDevice'].show()
                this.editInfo = {
                    unitIdentifier: row.item.untIdent,
                    description: row.item.untName,
                    untProperties: {
                        gridReferenceVolts: row.item.untProperties.gridReferenceVolts,
                        solarReferenceVolts: row.item.untProperties.solarReferenceVolts,
                        generatorReferenceVolts: row.item.untProperties.generatorReferenceVolts,
                        batteryMaxVolts: row.item.untProperties.batteryMaxVolts,
                        batteryMinVolts: row.item.untProperties.batteryMinVolts
                    }
                }
                this.editIndex = row.index
            },
            hideModals() {
                this.$refs['modal-editDevice'].hide()
                this.$refs['modal-addNewDevice'].hide()
            },
            saveDevice() {
                let firstChar = this.newUnit.unitIdentifier.substr(0, 1)
                this.errors = []
                this.showSuccess = false
                let ident = ''
                if (firstChar === '+') {
                    ident = this.newUnit.unitIdentifier.substr(1)
                } else {
                    ident = this.newUnit.unitIdentifier
                }

                let request = {
                    identifier: ident,
                    description: this.newUnit.description,
                    untProperties: {
                        gridReferenceVolts: this.newUnit.untProperties.gridReferenceVolts,
                        solarReferenceVolts: this.newUnit.untProperties.solarReferenceVolts,
                        generatorReferenceVolts: this.newUnit.untProperties.generatorReferenceVolts,
                        batteryMaxVolts: this.newUnit.untProperties.batteryMaxVolts,
                        batteryMinVolts: this.newUnit.untProperties.batteryMinVolts
                    }
                }
                this.$store.commit('setUpdateDeviceRequest', request)
                this.isLoading = true
                this.updateDevice()
                    .then(() => {
                        this.getLoggedInLocations()
                            .then(() => {
                                this.showSuccess = true
                                this.isLoading = false
                                this.hideModals()
                            })
                            .catch(() => {
                                this.isLoading = false
                                this.errors.push({
                                    message: 'Failed to load locations. Please refresh and try again.'
                                })
                            })
                    })
                    .catch(() => {
                        this.isLoading = false
                        this.errors.push({
                            message: 'Failed to load data. Please refresh and try again.'
                        })
                    })
            },
            editDevice() {
                let firstChar = this.editInfo.unitIdentifier.substr(0, 1)
                let ident = ''
                this.showSuccess = false
                if (firstChar === '+') {
                    ident = this.editInfo.unitIdentifier.substr(1)
                } else {
                    ident = this.editInfo.unitIdentifier
                }

                let request = {
                    identifier: ident,
                    description: this.editInfo.description,
                    untProperties: {
                        gridReferenceVolts: parseFloat(this.editInfo.untProperties.gridReferenceVolts),
                        solarReferenceVolts: parseFloat(this.editInfo.untProperties.solarReferenceVolts),
                        generatorReferenceVolts: parseFloat(this.editInfo.untProperties.generatorReferenceVolts),
                        batteryMaxVolts: parseFloat(this.editInfo.untProperties.batteryMaxVolts),
                        batteryMinVolts: parseFloat(this.editInfo.untProperties.batteryMinVolts)
                    }
                }
                this.$store.commit('setUpdateDeviceRequest', request)
                this.isLoading = true
                this.updateDevice()
                    .then(() => {
                        this.showSuccess = true
                        this.isLoading = false
                        this.locations[this.editIndex].untName = this.editInfo.description
                        this.locations[this.editIndex].untProperties.gridReferenceVolts = this.editInfo.untProperties.gridReferenceVolts
                        this.locations[this.editIndex].untProperties.solarReferenceVolts = this.editInfo.untProperties.solarReferenceVolts
                        this.locations[this.editIndex].untProperties.generatorReferenceVolts = this.editInfo.untProperties.generatorReferenceVolts
                        this.locations[this.editIndex].untProperties.batteryMaxVolts = this.editInfo.untProperties.batteryMaxVolts
                        this.locations[this.editIndex].untProperties.batteryMinVolts = this.editInfo.untProperties.batteryMinVolts
                        this.editIndex = -1
                        this.hideModals()
                    })
                    .catch((err) => {
                        console.log('error', err)
                    })
            },
            deleteDevice() {
                let request = {
                    id: this.locations[this.editIndex].untId
                }
                this.$store.commit('setRemoveDeviceRequest', request)
                this.removeDevice()
                    .then(() => {
                        this.locations.splice(this.editIndex, 1)
                        this.editIndex = -1
                        this.$store.commit('changeLocation', this.locations[0])
                        this.hideModals()
                    })
                    .catch(() => {
                        this.errors.push({message: 'Could not delete device'})
                        console.log('oops something went wrong')
                    })
            },
            changeSelectedLocation(index) {
                this.$store.commit('changeLocation', this.locations[index])
            }
        },
        computed: {
            ...mapState(['locations', 'selectedLocation'])
        }
    }
</script>